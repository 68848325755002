var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_vm._m(0), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "form-group m-form__group grid grid-cols-4 justify-start gap-4 mb-6"
  }, [_c('div', {}, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Search By Voucher Code")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "enterKeyAction": _vm.onSearch,
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Voucher Code"
    },
    model: {
      value: _vm.options['kode_voucher'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'kode_voucher', $$v);
      },
      expression: "options['kode_voucher']"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1)])]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', [_c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }, [_vm._v("Edit")]), (_vm.$store.getters['customer/customer'].role_id = 1) ? _c('button', {
          staticClass: "btn btn-danger",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMaster(props.row.id);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e()]) : props.column.field == 'exam_url' ? _c('span', [_c('a', {
          attrs: {
            "href": props.row.exam_url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_url))])]) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'periode' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field == 'exam_url_array' ? _c('span', _vm._l(props.row.exam_url_array, function (item_exam, index) {
          return _c('a', {
            key: index,
            attrs: {
              "href": item_exam,
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(item_exam))]);
        }), 0) : props.column.field == 'exam_sheet' ? _c('span', [_c('a', {
          attrs: {
            "href": 'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_sheet))])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Daily Ecommerce")])]);
}]

export { render, staticRenderFns }